body { 
	font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 14px;
}
p{
	margin: 0;
    line-height: 1.42857143;
}
img{
	max-width: 100%;
}
.mar-0{
	margin: 0px;
}
.logo_sec {
    background-image: url('../../assets/images/bgtop.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    text-align: center;
    background-color: #000000;
    color: #61CBFF;
    font-size: 13px;
    border: 0px;
    margin: 0px;
	img{
		width: 600px;
	}
}
.main_section {
    color: #2B2B2B;
    background: rgba(184,184,184,1);
    background: -moz-linear-gradient(top, rgba(184,184,184,1) 0%, rgba(255,255,255,1) 36%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(184,184,184,1)), color-stop(36%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(184,184,184,1) 0%, rgba(255,255,255,1) 36%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(184,184,184,1) 0%, rgba(255,255,255,1) 36%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(184,184,184,1) 0%, rgba(255,255,255,1) 36%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(184,184,184,1) 0%, rgba(255,255,255,1) 36%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b8b8b8', endColorstr='#ffffff', GradientType=0 );
	padding: 10px 0px;
}
.industry_box{
    background-color: #ffffff;
    -moz-border-radius: 25px 25px;
    -webkit-border-radius: 25px 25px;
    border-top: 3px solid rgba(13,106,181,1.00);
    border-bottom: 3px solid rgba(13,106,181,1.00);
    border-left: 13px solid rgba(13,106,181,1.00);
    border-right: 13px solid rgba(13,106,181,1.00);
    border-radius: 25px 25px;
	padding: 10px 30px;
	margin-bottom: 10px;
	
h4{
	color: rgba(17,88,154,1.00);
    text-transform: uppercase;
	font-size: 18px;
}	
ul{
	padding-left: 17px;
	
li{
	font-size: 13px;
	list-style-type: disc;
}
}
}

.industry_leftbox{
	width: 49%;
	display: inline-block;
	vertical-align: top;
}
.industry_rightbox{
	width: 49%;
	display: inline-block;
	vertical-align: top;
}
.certificate_box {
	text-align: center;
	margin-bottom: 20px;
h4{
    color: rgba(17,88,154,1.00);
    text-transform: uppercase;
	font-size: 18px;
	margin-bottom: 10px;
}
ul{
	display: flex;
	justify-content: center;
	padding: 0;
li{
	display: flex;
	margin: 0px 2px;
}
}
}
.certificate_btmimg img{
	width: 100%;
}

.right_sec{
	padding-left: 20px;
	
.video_box{
	margin-bottom: 20px;
	width: 640px;
}
.video_box video{
	width: 100%;
}
.whowe_txt {
	h4{
	color: rgba(17,88,154,1.00);
	text-transform: uppercase;
	font-size: 18px;
	}
	p{
    font-size: 13px;
	margin-bottom: 10px;
	}
.whowe_add{
	padding-top: 10px;
	
	p{
	margin: 0;
	strong{
		display: block;
	}
	}
}
}
}
.logo_txt p{
	font-size: 12px;
}
.logo_txt p a{
	text-decoration: none;
}
.logo_txt span .icon-phone img{
	width: 12px;
	margin-top:-2px;
	margin-right: 3px;
}
.logo_txt span .icon-flash img{
	width: 19px;
}
.logo_txt span.addycity{
    padding-left: 5px;
	padding-right: 10px;
    border-right: 1px solid #ffffff;
}
.logo_txt span.addyfax{
	padding-left: 10px;
}
.banner_sec{
	background-color: #000;
	padding: 25px 0px 0px;
}
.carousel_image {
	display: flex;
	width: 1140px;
    margin: 0 auto;
	padding-bottom: 65px;
}
.carousel_image img{
	height: 270px !important;
}
.carousel_image1{
	text-align: center;
}
.carousel_leftimage{
	width: 49%;
	display: inline-block;
	vertical-align: top;
}

.carousel_rightimage{
	width: 49%;
	display: inline-block;
	vertical-align: top;
}
.carousel-caption {
    padding: 0!important;
    bottom: 20px;
    left: 0;
    right: 0;
}
.carousel-caption h3{
	font-size: 20px;
}
.carousel-indicators{
	display: none;
}
.carousel-control-next-icon{
	background-image: url('../../assets/images/chevron-right.svg');
	background-color: #61CBFF;
    width: 30px;
    height: 30px;
	border-radius: 50%;
	top: -20%;
}
.carousel-control-prev-icon{
	background-image: url('../../assets/images/chevron-left.svg');
	background-color: #61CBFF;
    width: 30px;
    height: 30px;
	border-radius: 50%;
	top: -20%;
}
.carousel-control-prev {
    top: -50px;
	background-color: #000000 !important;
}
.carousel-control-next {
    top: -50px;
	background-color: #000000 !important;
}
/****about_sec****/
.about_sec{
	padding-top: 0;

.container{
	max-width: 83%;

.about_row{
	background: linear-gradient(to bottom, rgba(209,209,209,1) 0%, rgba(250,250,250,1) 10%, rgba(254,254,254,1) 18%, rgba(254,254,254,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#fefefe', GradientType=0 );
	padding: 30px 60px 10px;
	
	.about_toptxt{
		margin-bottom: 10px;
		h4{
		    font-size: 20px;
			color: #2b2b2b;
		}
		h1{
		    font-family: 'Lobster', cursive;
			font-size: 36px;
			margin-top: 20px;
			margin-bottom: 10px;
			color: #2b2b2b;
			padding: 0;
			font-weight: normal;
		}
		p{
		
		}
	}
	
	.about_topimg{
		
		width: 400px;
		margin: 0 auto;
		margin-bottom: 10px;
		img{
			width: 100%;
		}
	}
	.about_centerimg{
		width: 1080px;
		margin: 0 auto;
		margin-bottom: 20px;
		img{
			width: 100%;
			border-radius: 20px;
		}
	}
	
	.about_btmsec{
		margin-bottom: 20px;
		border-bottom: 1px dotted rgba(151,46,192,1.00);
		padding-bottom: 20px;
		
		.about_btmimg{
			text-align: center;
		}
		.about_btmtxt{
			p{
				font-size: 14px;
				margin-bottom: 20px;
				
			a{
			    color: #337ab7;
				text-decoration: none;
			}
			}
		}
	}
	
	.about_contact{
		align-items: center;
		.about_contactxt{
		    padding-left: 10px;
			p{
			font-size: 20px;
			}
		}
		.about_contactbtn{
			a{
			background-color: #5cb85c;
			border-color: #4cae4c;
			font-size: 14px;
			
			&:hover{
				background-color: #449d44;
				border-color: #398439;
			}
			}
		}
	}
}
}
}
/****quality_sec****/
.quality_sec{
	padding-top: 0;

.container{
	max-width: 83%;
	
.quality_row{
	background: linear-gradient(to bottom, rgba(209,209,209,1) 0%, rgba(250,250,250,1) 10%, rgba(254,254,254,1) 18%, rgba(254,254,254,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#fefefe', GradientType=0 );
	padding: 30px 60px 10px;
	
.quality_topsec{
	position: relative;
.quality_toptxt{
	padding-bottom: 10px;
	h1{
	    font-family: Lobster,cursive;
		font-size: 36px;
		margin-top: 20px;
		margin-bottom: 10px;
		color: #2b2b2b;
		padding: 0;
		font-weight: 400;
	}
	p{
		margin-bottom: 15px;
	}
}
	.quality_logos{
		float: right;
		ul{
			display: flex;
			justify-content: center;
			padding: 0;
		li{
			display: flex;
			margin: 0px 2px;
		}
		}
	}
}
.quality_causes{
	text-align: center; 
	margin-bottom: 40px;
	h2{
	    font-family: Lobster,cursive;
		font-size: 36px;
		margin-top: 20px;
		margin-bottom: 10px;
		color: #2b2b2b;
		padding: 0;
		font-weight: 400;
	}
	p{
		margin-bottom: 15px;
	}

}
.quality_pdf{
	margin-bottom: 30px;
	a{
	color: #337ab7;
	text-decoration: none;
	margin-bottom: 20px;
	display: block;
	}
	p{
	margin-bottom: 15px;
	}
}
.quality_txt{
	p{
	margin-bottom: 20px;
	
	strong{
		span{
			text-decoration: underline;
		}
	}
	}
}
}
}
}
/****contact_sec****/
.contact_sec{
	padding: 20px 0px;

.contact_row{
	width: 1285px;
	margin: 0 auto;
	
	.contact_title{
		text-align: center;
		margin-bottom: 20px;
		h1{
			font-size: 36px;
		}
	}
	.contact_form{
		background-color: #ffffff;
		-moz-border-radius: 25px 25px 25px 25px;
		-webkit-border-radius: 25px 25px 25px 25px;
		border-top: 3px solid rgba(13,106,181,1.00);
		border-bottom: 3px solid rgba(13,106,181,1.00);
		border-left: 13px solid rgba(13,106,181,1.00);
		border-right: 13px solid rgba(13,106,181,1.00);
		border-radius: 25px 25px 25px 25px;
		padding: 5px 30px 20px;
		
	p{
		margin-bottom: 10px;
		font-size: 13px;
	}	
	}
	.contact_txt{
		h5{
		    font-weight: 700;
			font-size: 14px;
			margin-bottom: 5px;
		}
		p{
		
		a{
		    color: #2B2B2B;
			text-decoration: none;
		}
		}
	.contact_add{
		margin-bottom: 30px;
	}
	.contact_map{
		margin-bottom: 30px;
	}
	.contact_addemail{
		margin-bottom: 20px;
		
		p{
		margin-bottom: 20px;
		}
	a{
		color: #337ab7;
	}
	}
	}
}
}
.contact_form .form-group{
	margin-bottom: 10px;
	width: 100%;
}
.contact_form .form-group.w80{
	width: 80%;
}
.contact_form .form-group.w50{
	width: 50%;
}
.contact_form .form-group.w30{
	width: 30%;
}
.contact_form .form-group.w25{
	width: 25%;
}
.contact_form .form-group.w20{
	width: 20%;
}
.contact_form label{
	display: block;
	margin-bottom: 5px;
	font-size: 13px;
}
.contact_form input{
	display: block;
	width: 100%;
	border-radius: 0;
	padding: 4px 5px;
    border: 1px solid #666666;
	outline: none;
}
.contact_form textarea{
	width: 100%;
	height: 70px;
	border: 1px solid #666666;
    outline: none;
}
.recaptcha{
	width: 304px;
	margin: 20px 0px 40px;
}
.form-submit input[type=submit]{
    width: 100%;
    padding: 15px;
    border: 1px solid #666666;
    background-color: #4180C5;
    color: aliceblue;
    font-size: 15px;
}
/*****gallery_sec*****/
.gallery_sec{
    padding-top: 0;

.container {
    max-width: 83%;
	
.gallery_row {
    background: -webkit-gradient(linear,left top,left bottom,from(#d1d1d1),color-stop(10%,#fafafa),color-stop(18%,#fefefe),to(#fefefe));
    background: -o-linear-gradient(top,#d1d1d1 0,#fafafa 10%,#fefefe 18%,#fefefe 100%);
    background: linear-gradient(to bottom,#d1d1d1 0,#fafafa 10%,#fefefe 18%,#fefefe 100%);
    padding: 30px 60px 10px;
	
.gallery_txt{
	p{
		font-size: 15px;
	}
	h1{
		font-family: Lobster,cursive;
		font-size: 36px;
		margin-top: 20px;
		margin-bottom: 10px;
		color: #2b2b2b;
		padding: 0;
		font-weight: 400;
	}
}
}	
}
}
.gallery-col{
    background: #fff;
	padding-top: 20px;
}
.gallery-col a{
    display: block;
	margin-bottom: 20px;
}
.gallery-col p{
    font-size: 12px;
}
.img-rounded{
	border-radius: 6px;
}
/****hydroform_sec****/
.hydroform_sec{
	padding-top: 0;

.container{
	max-width: 83%;

.hydroform_row{
	background: linear-gradient(to bottom, rgba(209,209,209,1) 0%, rgba(250,250,250,1) 10%, rgba(254,254,254,1) 18%, rgba(254,254,254,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#fefefe', GradientType=0 );
	padding: 30px 60px 10px;
	
	.hydroform_toptxt{
		margin-bottom: 10px;
		h4{
		    font-size: 20px;
			color: #2b2b2b;
		}
		h1{
		    font-family: 'Lobster', cursive;
			font-size: 36px;
			margin-top: 20px;
			margin-bottom: 10px;
			color: #2b2b2b;
			padding: 0;
			font-weight: normal;
		}
		p{
		
		}
	}
	
	.hydroform_topimg{
		
		width: 400px;
		margin: 0 auto;
		margin-bottom: 10px;
		img{
			width: 100%;
		}
	}
	.hydroform_centerimg{
		width: 1280px;
		margin: 0 auto;
		margin-bottom: 20px;
		img{
			width: 100%;
			border-radius: 20px;
		}
	}
	
.hydroform_para{
	padding: 0px 20px;
	h3{
	    margin-top: 20px;
		margin-bottom: 10px;
		font-size: 24px;
	
	span{
	    color: #07ABEE;
	}
	}
	p{
		margin-bottom: 25px;
	}
}

.hydroform_parabox{
    padding: 20px 20px 0px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
	h3{
	    margin-top: 20px;
		margin-bottom: 10px;
		font-size: 24px;
	span{
	    color: #07ABEE;
	}
	}
	p{
		margin-bottom: 25px;
	}
}
}
}
}
.hydroform_btm{
	padding:0;
}
.hydroform_btm li {
	display: inline-block;
	vertical-align: top;
	margin-right: 10px;
}
.hydroform_btm li a {
    background-color: #5cb85c;
    border-color: #4cae4c;
	font-size: 14px;
}
.hydroform_sec .container .about_row .hydroform_para p.mar-0{
	margin: 0px;
}
/****hydroprocess_sec****/
.hydroprocess_sec{
	padding-top: 0;

.container{
	max-width: 83%;

.hydroprocess_row{
	background: linear-gradient(to bottom, rgba(209,209,209,1) 0%, rgba(250,250,250,1) 10%, rgba(254,254,254,1) 18%, rgba(254,254,254,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#fefefe', GradientType=0 );
	padding: 0px 15px 10px 40px;
	
	.hydroprocess_toptxt{
		margin-bottom: 10px;
		padding-top: 30px;
		h1{
		    font-family: 'Lobster', cursive;
			font-size: 36px;
			margin-top: 20px;
			margin-bottom: 10px;
			color: #2b2b2b;
			padding: 0;
			font-weight: normal;
		}
		p{
		margin-bottom: 20px;
		}
	.hypescale{
		margin-bottom: 20px;
	}
	.hydro_txt{
		margin-bottom: 10px;
	}
	.hydro_list{
		h4{
		    font-size: 18px;
			margin-bottom: 10px;
		}
		ul{
			padding-left: 15px;
			columns: 2;
		}
	}
	}
	
.hydroprocess_box{	
	min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
	box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.05);

h5{
    padding: 10px;
    background-color: #000000;
    color: #ffffff;
    text-transform: none;
    border: 0px solid #000000;
    border-radius: 0px 10px 10px 0px;
	font-size: 14px;
}
.hydroprocess_boxtxt{
	margin: 0px 20px;
	
h6{
    color: #666666;
    text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	margin-bottom: 0px;
}
ul{
	margin-bottom: 10px;
	li{
	    font-size: 12px;
	}
}
	
}

}
}
}
}

 .hypescale {
	height:320px;
	width:500px !important;
 }
#hypescale2 {
	visibility: hidden;
	height:0px;
	display:none
 }
 
.fancybox-inner button:not(.toggle) {
    background-color: transparent !important;
}

.def-page article, .def-page main {
    background: -webkit-gradient(linear, left top, left bottom, from(#d1d1d1), color-stop(10%, #fafafa), color-stop(18%, #fefefe), to(#fefefe));
    background: -o-linear-gradient(top, #d1d1d1 0%, #fafafa 10%, #fefefe 18%, #fefefe 100%);
    background: linear-gradient(to bottom, #d1d1d1 0%, #fafafa 10%, #fefefe 18%, #fefefe 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#fefefe', GradientType=0 );
    padding: 30px 60px 60px;
}
.def-page h1 {
    font-family: 'Lobster', cursive;
    font-size: 36px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #2b2b2b;
    padding: 0;
    font-weight: normal;
	text-align: left;
}
.def-page a {
    color: #337ab7;
	text-decoration: none;
}
.def-page .intro-text p{
	margin-bottom: 20px;
}
.def-page form{
	display: flex;
}
.def-page input {
    display: block;
    width: 100%;
    border-radius: 0;
    padding: 4px 5px;
    border: 1px solid #666666;
    outline: none;
}
.def-page input[type=submit] {
    width: auto;
    padding: 4px 30px;
    border: 0;
    background-color: #4180C5;
    color: aliceblue;
    font-size: 15px;
    display: inline-block;
	    margin-left: 10px;
}
.search .content-area{
    width: 1285px;
    margin: 0 auto;
	padding: 20px 0px;
}
.search #sidebar{
    width: 1285px;
    margin: 0 auto;
	padding: 20px 0px;
}
.search #sidebar ul {
    padding:0;
}
.search #sidebar ul li {
    display: block;
}
.search #sidebar ul li form{
	display: flex;
	margin-bottom: 20px;
}
.search #sidebar ul li input {
    display: block;
    width: 100%;
    border-radius: 0;
    padding: 4px 5px;
    border: 1px solid #666666;
    outline: none;
}
.search #sidebar ul li input[type=submit] {
    width: auto;
    padding: 4px 30px;
    border: 0;
    background-color: #4180C5;
    color: aliceblue;
    font-size: 15px;
    display: inline-block;
	    margin-left: 10px;
}
.search #sidebar ul li p a {
    color: #337ab7;
	text-decoration: none;
}
.search #sidebar ul li h2{
	font-size: 36px;
}
.search #sidebar ul li ul{
	margin-bottom: 20px;
}
.search #sidebar ul li ul li {
    font-size: 13px;
    list-style-type: disc;
}
.search #sidebar ul li ul li a {
	color: #000000;
	text-decoration: none;
	
}