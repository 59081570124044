
/* START :: Custom variable */
html {
    scroll-behavior: smooth;
}

$px72: 4.5rem;
$px69: 4.3125em;
$px64: 4rem;
$px62: 3.875rem;
$px60: 3.75rem;
$px58: 3.625rem;
$px56: 3.5rem;
$px55: 3.4375rem;
$px54: 3.375rem;
$px52: 3.25rem;
$px50: 3.125rem;
$px48: 3rem;
$px46: 2.875rem;
$px45: 2.8125rem;
$px44: 2.75rem;
$px42: 2.625rem;
$px40: 2.5rem;
$px38: 2.375rem;
$px36: 2.25rem;
$px35: 2.1875rem;
$px34: 2.125rem;
$px32: 2rem;
$px30: 1.875rem;
$px29: 1.8125rem;
$px28: 1.75rem;
$px26: 1.625rem;
$px25: 1.5625rem;
$px24: 1.5rem;
$px22: 1.375rem;
$px21: 1.3125rem;
$px20: 1.25rem;
$px19: 1.1875rem;
$px18: 1.125rem;
$px17: 1.0625rem;
$px16: 1rem;
$px15: 0.9375rem;
$px14: 0.875rem;
$px13: 0.8125rem;
$px12: 0.75rem;

$gray_bg : #eaeaea;
$black_bg : #212b27;  
$gold_bg : #cda075;
$btn_bg : #cda075;


.gutter-100 {padding: 100px 0;}
.gutter-t-100 { padding-top: 100px}
.gutter-b-100 {padding-bottom: 100px;}

.gutter-90 {padding: 90px 0;}
.gutter-t-90 { padding-top: 90px}
.gutter-b-90 {padding-bottom: 90px;}

.gutter-80 { padding: 80px 0;}
.gutter-t-80 { padding-top: 80px}
.gutter-b-80 { padding-bottom: 80px;}

.gutter-xy-70{ padding: 70px;}
.gutter-70 { padding: 70px 0;}
.gutter-t-70 {padding-top: 70px!important;}
.gutter-b-70 { padding-bottom: 70px!important;}

.gutter-xy-60{ padding: 60px;}
.gutter-60 { padding: 60px 0;}
.gutter-t-60 {padding-top: 60px!important;}
.gutter-b-60 { padding-bottom: 60px!important;}

.gutter-xy-50{ padding: 50px;}
.gutter-50 { padding: 50px 0;}
.gutter-t-50 { padding-top: 50px}
.gutter-b-50 {padding-bottom: 50px}

.gutter-xy-45 {padding: 45px}
.gutter-45 { padding: 45px 0;}
.gutter-t-45 { padding-top: 45px}
.gutter-b-45 {padding-bottom: 45px}

.gutter-xy-40 {padding: 40px}
.gutter-40 { padding: 40px 0;}
.gutter-t-40 { padding-top: 40px}
.gutter-b-40 {padding-bottom: 40px}

.gutter-xy-30 { padding: 30px;}
.gutter-30 { padding: 30px 0;}
.gutter-t-30 { padding-top: 30px}
.gutter-b-30 {padding-bottom: 30px}


.gutter-xy-20 { padding: 20px;}
.gutter-20 { padding: 20px 0;}
.gutter-t-20 { padding-top: 20px}
.gutter-b-20 {padding-bottom: 20px}

.gutter-xy-15 { padding: 15px;}
.gutter-15 { padding: 15px 0;}
.gutter-t-15 { padding-top: 15px}
.gutter-b-15 {padding-bottom: 15px}

/* END :: Custom variable */
@font-face {
    font-family: 'madebonVoyage';
    src: url('../fonts/madebonVoyage.eot');
    src: url('../fonts/madebonVoyage.eot?#iefix') format('embedded-opentype'),
        url('../fonts/madebonVoyage.woff2') format('woff2'),
        url('../fonts/madebonVoyage.woff') format('woff'),
        url('../fonts/madebonVoyage.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/* START :: Bootstrap variable  */
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1400px,
  xxl: 1430px
) !default;
// scss-docs-end container-max-widths
$font-size-root: 16px !default;
$primary: #000000 !default;
$secondary: #cda075 !default;
$font-family-sans-serif : 'Montserrat', sans-serif;
$seconday_font : 'madebonVoyage';
$h1-font-size: $px72 !default;
$h2-font-size: $px69 !default;
$h3-font-size: $px55 !default;
$h4-font-size: $px36 !default;
$h5-font-size: $px24 !default;
$h6-font-size: $px20 !default;
$input-placeholder-color: #000 !default;
/*END :: Bootstrap variable*/


/*
font-family: 'Montserrat', sans-serif;
font-family: 'madebonVoyage'*/