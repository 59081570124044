@media(max-width: 1739px) { 
.hydroform_sec .container .about_row .hydroform_centerimg {
    width: 100%;
}
}
@media(max-width: 1539px) { 
.about_sec .container .about_row .about_topimg {
    width: 100%;
}
.about_sec .container .about_row .about_centerimg {
    width: 100%;
}
.hydroform_sec .container .about_row .hydroform_topimg{
    width: 100%;
}
}
@media(max-width: 1439px) { 
.right_sec .video_box {
	width: 100%;
}
.container{
    max-width: 90%;
}
.contact_sec .contact_row {
    width: 100%;
}
}
@media(max-width: 1279px) {
.carousel_image {
    width: 75%;
}
.carousel_image img {
    height: 200px!important;
}
.industry_box {
    padding: 10px 20px;
}
.gallery_sec .container .gallery_row {
    padding: 30px 40px 10px;
	}
.hydroprocess_sec .container .hydroprocess_row {
    padding: 0 20px 10px 40px;
}
.hydroprocess_row .hyd-col{
	padding-right: 0; 
}

}
@media(max-width: 1079px) {
.hydroprocess_sec .container .hydroprocess_row {
    padding: 0 20px 10px 20px;
}
}
@media(max-width: 991px) {
.carousel_image img {
    height: 165px!important;
}
.left_sec{
	margin-bottom: 40px;
}
.right_sec{
	padding: 0;
	margin-bottom: 20px;
}
.nav-icon {
	display: block;
}
.header {
    padding: 25px 0;
}
.my_nav{
	display: none;
}
.open .my_nav {
    display: block;
	padding: 15px 30px;
	position: fixed;
    left: 0;
    top: 50px;
    background: #000;
    width: 100%;
	z-index: 9;
	overflow: auto;
    max-height: calc(100vh - 50px);
    max-height: -moz-calc(100vh - 50px);
}
.about_sec .container .about_row {
    padding: 30px 30px 10px;
}
.about_sec .container .about_row .about_topimg{
	width: 400px;
}
.about_sec .container .about_row .about_btmsec{
	flex-direction: column-reverse;
}
.about_sec .container .about_row .about_contact .about_contactxt{
	margin-bottom: 10px;
	padding: 0;
}
.quality_sec .container .quality_row {
    padding: 30px 30px 10px;
}
.contact_form {
	margin-bottom: 30px;
}
.gallery_sec .container .gallery_row {
    padding: 30px 15px 10px;
}
.hydroform_sec .container .about_row {
    padding: 30px 30px 10px;
}

}

@media(max-width: 767px) {
.carousel_image img.mobigone{
	display: none;
}
.carousel_image img {
    height: auto !important;
}
.logo_txt span.addyfax {
    padding-left: 0px;
    display: block;
}
.logo_txt span.addycity {
    border-right: 0;
}
.about_sec .container, .quality_sec .container {
    max-width: 100%;
}
.about_sec .container .about_row, .quality_sec .container .quality_row  {
    padding: 30px 15px 10px;
}
.contact_sec .container {
    max-width: 100%;
}
.contact_sec .contact_row .contact_form {
    padding: 5px 15px 20px;
}
.contact_form .form-group.w80 {
    width: 90%;
}
.contact_form .form-group.w50 {
    width: 70%;
}
.contact_form .form-group.w30 {
    width: 55%;
}
.contact_form .form-group.w50 {
    width: 80%;
}
.contact_form .form-group.w30 {
    width: 70%;
}

.contact_form .form-group.w20 {
    width: 60%;
}
.contact_form .form-group.w25 {
    width: 50%;
}
.gallery-col a {
    display: block;
    margin: 0 auto;
	margin-bottom: 20px;
    text-align: center;
}
.gallery_sec .container {
    max-width: 100%;
}
.gallery_sec .container .gallery_row {
    padding: 30px 0px 10px;
}
.hydroform_sec .container {
    max-width: 100%;
}
.hydroform_sec .container .about_row {
    padding: 30px 15px 10px;
}
.hydroform_sec .container .about_row .hydroform_para {
    padding: 0 0px;
}
.hydroprocess_sec .container {
    max-width: 100%;
}
.hydroprocess_sec .container .hydroprocess_row {
    padding: 0 0px 10px 0px;
}
.hydroprocess_row .hyd-col {
    padding: 0;
}
.hydroprocess_sec .container .hydroprocess_row .hydroprocess_toptxt .hydro_list ul {
    columns: 1;
}
}
@media(max-width: 576px) {  
.industry_leftbox {
    width: 100%;
    display: block;
	margin-bottom: 20px;
}
.industry_rightbox {
    width: 100%;
    display: block;
}
.footer p {
   width: 100%;
}
.quality_sec .container .quality_row .quality_topsec .quality_logos {
    float: none;
}
.hypescale{
	display: none;
	visibility: hidden;
    height: 0px !important;
    width: 0px !important;
}
#hypescale2 {
    height: 220px !important;
    width: 100% !important;
    visibility: visible !important;
    display: inline-block !important;
    border: 1px solid #666666;
}
#hypescale2 video {
    width: 330px !important;
}
}
@media(max-width: 479px) {  
.logo_txt span.addycity {
    width: 100%;
    display: block;
}
.carousel-control-prev-icon {
    width: 25px;
    height: 25px;
    background-size: 20px;
}
.carousel-control-next-icon {
    width: 25px;
    height: 25px;
    background-size: 20px;
}
.about_sec .container .about_row .about_topimg {
    width: 100%;
}
.form-submit input[type=submit] {
    padding: 5px 15px;
}
}
@media(max-width: 375px) {
.recaptcha {
    width: 100%;
}
#hypescale2 video {
    width: 270px!important;
}
#hypescale2 {
    height: 180px!important;
}
}