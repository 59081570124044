.logged-in {
    .header { 
        top: 32px;
    }
}
.header{
    z-index: 100;
	background: #000000;
	padding: 9px 0px;
    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0px 0px 21px -8px rgba(0,0,0,0.21);
        .top-header {
            display: none;
        }
    }
    .navbar{
		padding: 0;
		
	.my_nav{
		justify-content: center;
		ul{
			li{
				margin: 0px 2px;
				a{
					color: #ffffff;
					text-decoration: none;
					padding: 6px 6px;
					display: block;
					font-size: 12px;
					
				&:hover{
					background-color: #48A3EB;
					color: #ffffff;
				}
				}
			}
		}
	}
	}
}
/**********nav-icon**********/
.nav-icon { display: none; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); 
transform: rotate(0deg); -webkit-transition: .5s ease-in-out; -moz-transition: .5s ease-in-out; -o-transition: .5s ease-in-out;
transition: .5s ease-in-out; cursor: pointer; z-index: 9; width: 25px; height: 19px; position: absolute; top: -8px; right: 0px;
border-radius: 0; }
.nav-icon span{display: block; position: absolute; height: 2px; background: #ffffff; opacity: 1; left: 6px; 
-webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); 
-webkit-transition: .25s ease-in-out; -moz-transition: .25s ease-in-out;-o-transition: .25s ease-in-out;
 transition: .25s ease-in-out; border-radius: 20px; }
.nav-icon span:nth-child(1) { top: 0px; width: 25px; left: auto; }
.nav-icon span:nth-child(2)	{ top: 7px; width: 25px;  left: auto;  }
.nav-icon span:nth-child(3)	{ top: 14px; width: 25px;  left: auto;  }
.nav-icon.open span:nth-child(1){ top: 10px; right: 0px; -webkit-transform: rotate(135deg);-moz-transform: rotate(135deg);
 -o-transform: rotate(135deg); transform: rotate(135deg);}
.nav-icon.open span:nth-child(2){ 	opacity: 0; }
.nav-icon.open span:nth-child(3){ top: 10px;-webkit-transform: rotate(-135deg); -moz-transform: rotate(-135deg); 
-o-transform: rotate(-135deg);transform: rotate(-135deg); width: 25px;  right: 0px; }

