.footer {
	color: #ffffff;
    background-color: rgba(13,106,181,1.00);
	text-align: center;
	padding: 25px 0px 50px;
	p{
		font-size: 14px;
		width: 410px;
		margin: 0 auto;
	}
}
